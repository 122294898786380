
.map {
    background: gray;
    height: calc(100vh - 48px);
    width: 100%;
}

.leaflet-container {
    height: 100%;
}


@media (max-width: 500px) {
    .map {
        height: calc(100vh - 200px);
    }
}
