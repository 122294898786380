button, .btn {
    background: #2A913E;
    padding: 1px 35px;
    color: white;
    border: none;
    text-transform: uppercase;
    border-radius: 2px;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
}

@media (max-width: 640px) {
    button, .btn {
        padding: 1px 5px;
        font-size: 0.7rem;
    }
}

@media (max-width: 500px) {
    button, .btn {
        flex-direction: column;
        padding: 1px 5px;
        font-size: 0.6rem;
        margin-top: 5px;
        text-align: center;
    }
}
